import { cleanErrorObject } from 'common/dist/validation/helpers';
import { DeepPartial } from 'react-hook-form';

import {
  CassandraTableSelectAugurSettings,
  CassandraTableSelectValidationError,
} from './type';

export const validateCassandraTableSelect = (
  value: CassandraTableSelectAugurSettings
): DeepPartial<CassandraTableSelectValidationError> | undefined => {
  const error: DeepPartial<CassandraTableSelectValidationError> = {};

  if (!value) {
    error.global = 'Please select a table';
  }
  return cleanErrorObject(error);
};
