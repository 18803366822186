import React, { Component, ComponentType, PropsWithChildren } from 'react';
import { FormattedMessage, MessageDescriptor } from 'react-intl';
import './styles.scss';
import classNames from 'classnames';

export interface Props {
  errorHeadline?: MessageDescriptor;
  errorDescription?: MessageDescriptor;
  actions?: ComponentType;
  /** Sets "height: 100vh" to the Error Boundary */
  fullScreenHeight?: boolean;
}

export default class ErrorPage extends Component<Props> {
  static defaultProps = {
    errorHeadline: {
      id: 'error.headline',
      defaultMessage: 'An unexpected error occurred.',
    },
  };
  props: PropsWithChildren<Props>;

  constructor(props: PropsWithChildren<Props>) {
    super(props);
  }

  render() {
    const {
      errorHeadline,
      errorDescription,
      actions,
      fullScreenHeight,
    } = this.props;

    return (
      <div
        className={classNames('ErrorBoundary', {
          'ErrorBoundary-100vh': fullScreenHeight,
        })}
      >
        <div className={'ErrorBoundary--picture error--pic'}>&nbsp;</div>

        <FormattedMessage {...errorHeadline}>
          {(txt) => <span className={'ErrorBoundary--headline'}>{txt}</span>}
        </FormattedMessage>

        {errorDescription && (
          <FormattedMessage {...errorDescription}>
            {(txt) => (
              <span className={'ErrorBoundary--description'}>{txt}</span>
            )}
          </FormattedMessage>
        )}

        {actions && <div className={'ErrorBoundary--actions'}>{actions}</div>}
      </div>
    );
  }
}
