import { cleanErrorObject } from 'common/dist/validation/helpers';
import { DeepPartial } from 'react-hook-form';

import {
  TextInputLineAugurSettings,
  TextInputLineConfig,
  TextInputLineValidationError,
} from './type';

export const validateTextInputLine = (
  value: TextInputLineAugurSettings,
  config: TextInputLineConfig
): DeepPartial<TextInputLineValidationError> | undefined => {
  const error: DeepPartial<TextInputLineValidationError> = {};

  if (!config.isOptional && !value?.length) {
    error.global = 'Input required';
  }
  return cleanErrorObject(error);
};
