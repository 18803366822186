import classNames from 'classnames';
import React, { FC, Fragment } from 'react';
import { FiCheckCircle, FiSlash } from 'react-icons/fi';
import { MdCategory } from 'react-icons/md';
import { TiSortNumerically } from 'react-icons/ti';

import Bar from './Bar';
import styles from './styles.module.scss';
import { Feature } from '../type';

export type Props = {
  feature: Feature;
};

const InfluencerBar: FC<Props> = (props: Props) => {
  const { feature } = props;

  const renderActive = () => {
    return (
      <Fragment>
        <div
          className={classNames(styles.discardedOrUsedIcon, styles.iconUsed)}
          title={'Feature is used for the model'}
        >
          <FiCheckCircle size={16} />
        </div>
        <div className={classNames(styles.featureText)} title={feature.feature}>
          <span>{feature.feature}</span>
        </div>
      </Fragment>
    );
  };

  const renderDiscarded = () => {
    return (
      <Fragment>
        <div
          className={classNames(
            styles.discardedOrUsedIcon,
            styles.iconDiscarded
          )}
          title={
            feature.discarded &&
            'Feature is auto-rejected:\n' + feature.discarded.join(', ')
          }
        >
          <FiSlash size={16} />
        </div>

        <div className={classNames(styles.featureText)} title={feature.feature}>
          <span className={classNames(styles.discarded)}>
            {feature.feature}
          </span>
        </div>
      </Fragment>
    );
  };

  return (
    <>
      <span className={classNames(styles.item, styles.importance)}>
        <Bar importance={feature.importance} />
      </span>

      <div className={classNames(styles.item, styles.type)}>
        <div
          className={styles.discardedOrUsedIcon}
          title={
            feature.featureType === 'numerical' ? 'Numerical' : 'Categorical'
          }
        >
          {feature.featureType === 'numerical' ? (
            <TiSortNumerically size={16} />
          ) : (
            <MdCategory size={16} />
          )}
        </div>
      </div>

      <div className={classNames(styles.item, styles.feature)}>
        {!feature.discarded ? renderActive() : renderDiscarded()}
      </div>
    </>
  );
};

export default InfluencerBar;
