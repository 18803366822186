import classNames from 'classnames';
import _ from 'lodash';
import React, { FC, useState } from 'react';
import { FiChevronDown } from 'react-icons/fi';
import { FormattedMessage } from 'react-intl';

import Header from './Header';
import InfluencerBar from './InfluencerBar';
import { OrderByType, SortByType, sortFeatures } from './sorting';
import styles from './styles.module.scss';
import GraphWait from '../../../../../../../components/details/GraphWait';
import { buildSingleReportWrapper } from '../../../common/SingleReportWrapper';
import { ReportElementProps } from '../../../types/meta';
import { FeatureImportanceConfig, FeatureImportanceReportData } from '../type';

export type Props = FeatureImportanceReportData & FeatureImportanceConfig;

const FEATURES_TO_SHOW_COUNT = 100;

const FeatureImportance: FC<Props> = (props: Props) => {
  const { featureImportanceCalculatedWith, features } = props;

  const [sortBy, setSortBy] = useState<SortByType>('importance');
  const [orderBy, setOrderBy] = useState<OrderByType>('descending');
  const [numFeaturesToShow, setNumFeaturesToShow] = useState<number>(
    FEATURES_TO_SHOW_COUNT
  );

  if (_.isEmpty(features)) {
    return (
      <GraphWait jobType={'learning'}>
        <FormattedMessage
          id='augur.influencers.not_available'
          defaultMessage='Influencers are not available'
        />
      </GraphWait>
    );
  }

  const processedFeatures = features.sort((f1, f2) =>
    sortFeatures(f1, f2, sortBy, orderBy)
  );

  const featuresToShow = processedFeatures.slice(0, numFeaturesToShow);

  return (
    <>
      <div className={styles.infoText}>
        {'Feature importance was calculated with ' +
          featureImportanceCalculatedWith +
          '.'}
      </div>

      <div className={classNames(styles.grid, styles.featureImportance)}>
        <Header
          sort={(sortBy, orderBy) => {
            setSortBy(sortBy);
            setOrderBy(orderBy);
          }}
          sortBy={sortBy}
          orderBy={orderBy}
        />

        {featuresToShow.map((f) => (
          <InfluencerBar key={`feature-${f.feature}`} feature={f} />
        ))}
      </div>

      {numFeaturesToShow < processedFeatures.length && (
        <button
          type={'button'}
          className={styles.showMoreButton}
          id={'collapsableWizardButton'}
          onClick={() => {
            setNumFeaturesToShow(numFeaturesToShow + FEATURES_TO_SHOW_COUNT);
          }}
        >
          <FormattedMessage
            id='dashboard.show_more'
            defaultMessage='Show More'
          />
          <FiChevronDown className={styles.buttonIcon} />
        </button>
      )}
    </>
  );
};

export const FeatureImportanceSingle: FC<
  ReportElementProps<FeatureImportanceReportData, FeatureImportanceConfig>
> = buildSingleReportWrapper<
  FeatureImportanceReportData,
  FeatureImportanceConfig
>(FeatureImportance);

export default FeatureImportance;
