import { LineChartV1ReportData as LineChartReportData } from 'common/src/types/reportData/lineChartV1ReportData';

import { lineChart2 } from './single/exampleData';
import { Config } from '../../../../../pages/augur/type';
export type { LineChartReportData };

export type LineChartConfig = {
  yLabel?: string;
  xLabel?: string;
  yAxisMin?: 'dataMin' | number;
};

export type ConfigLineChartConfig = Config<LineChartConfig>;

export const configTypeLineChart = `{
  xLabel?: string;
  yLabel?: string;
  yAxisMin?: 'dataMin' | number;
}`;
export const reportDataTypeLineChart = `{
  data: [number, number][];
  baseLine?: number;
  helpLine?: [[number, number], [number, number]];
}`;

export const defaultConfigLineChart: ConfigLineChartConfig = {
  xLabel: { source: 'hard-coded', value: 'X-Axis' },
  yLabel: { source: 'hard-coded', value: 'Y-Axis' },
  yAxisMin: { source: 'hard-coded', value: 0 },
};

export const defaultReportDataDefaultLineChart: LineChartReportData = {
  data: lineChart2,
};
