import { useQueryClient } from '@tanstack/react-query';
import commonMessages from 'common/dist/messages/common';
import moduleMsgs from 'common/dist/messages/modules';
import { AUGUR_JOBS } from 'common/dist/types/job';
import { contentArrayToPath } from 'common/dist/utils/workbench/content';
import React, { FC } from 'react';
import {
  Controller,
  FormProvider,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import { useSelector } from 'react-redux';

import {
  fromGeneralConfigForm,
  GeneralConfigurationForm,
  toGeneralConfigurationForm,
} from './generalConfig.form';
import styles from './styles.module.scss';
import { modulesKeys, useModuleFiles } from '../../../core/api/modules';
import { saveConfig } from '../../../core/api/workbench/content';
import { RootState } from '../../../store/store';
import Button from '../../atoms/button/Button';
import { IntlCheckbox } from '../../atoms/react-hook-form-input-elements/checkbox/Checkbox';
import ResourceInput from '../../atoms/react-hook-form-input-elements/resource-input/ResourceInput';
import { validateK8sResources } from '../../molecules/k8s-resources-select/validate';
import { useSelectedDirPath } from '../../workbench/hooks';
import { baseConfig } from '../augur/config.template';
import { ModuleConfiguration } from '../augur/type';

export type Props = {
  notebookUser: string;
};

const GeneralConfigurationPage: FC<Props> = (props) => {
  const { notebookUser } = props;
  const selectedDirPath = useSelectedDirPath();
  const index = selectedDirPath.findIndex((x) => x.endsWith('.asr'));
  const queryClient = useQueryClient();
  const enableGpuFractions = useSelector<RootState, boolean>(
    (state) => state.config?.enableGpuFractions
  );
  const { data: moduleFilesData } = useModuleFiles(
    contentArrayToPath(selectedDirPath.slice(0, index + 1), false),
    index !== -1
  );
  //Local variables to simplify the tree
  const actualConfig = moduleFilesData?.config
    ? moduleFilesData.config
    : baseConfig;

  // construct config form
  const configFormMethods = useForm<GeneralConfigurationForm>({
    mode: 'all',
    values: toGeneralConfigurationForm(actualConfig.generalConfiguration),
  }); // We explicitly do NOT use defaultValues here (see below)

  const {
    control,
    handleSubmit,
    reset,
    watch,
    formState: { isValid, isDirty, dirtyFields },
  } = configFormMethods;

  const formWatcher = watch();

  async function invalidateModuleFilesData() {
    await queryClient.invalidateQueries(
      modulesKeys.files(
        contentArrayToPath(selectedDirPath.slice(0, index + 1), false)
      )
    );
  }

  const onSubmit: SubmitHandler<GeneralConfigurationForm> = async (
    generalConfig
  ) => {
    const newConfig: ModuleConfiguration = {
      ...actualConfig,
      generalConfiguration: fromGeneralConfigForm(generalConfig),
    };
    await saveConfig(notebookUser, newConfig, selectedDirPath, index).then(
      invalidateModuleFilesData
    );

    // Reset the form after successful submission
    reset(generalConfig);
  };
  return (
    <div className={styles.Container}>
      <div className={styles.Header}>
        <span>General Configuration</span>
      </div>

      <FormProvider {...configFormMethods}>
        <form
          className={styles.devAugurContainer}
          onSubmit={handleSubmit(onSubmit)}
          // prevent submission on enter
          onKeyDown={(e) => {
            e.key === 'Enter' &&
              !(e.target instanceof HTMLTextAreaElement) &&
              e.preventDefault();
          }}
        >
          <div className={styles.HeaderLeft}>
            <div className={styles.FullLine} />
            <span>Jobs</span>
          </div>
          <Controller
            name={'supportsLearning'}
            control={control}
            render={({ field, fieldState, formState }) => {
              const { ref, value, ...rest } = field; // extract ref to pass as inputRef
              return (
                <IntlCheckbox
                  {...rest}
                  {...fieldState}
                  {...formState}
                  checked={value}
                  inputRef={ref}
                  label={moduleMsgs.moduleConfigurationPageSupportsLearning}
                />
              );
            }}
          />
          <Controller
            name={'supportsEvaluation'}
            control={control}
            render={({ field, fieldState, formState }) => {
              const { ref, value, ...rest } = field; // extract ref to pass as inputRef
              return (
                <IntlCheckbox
                  {...rest}
                  {...fieldState}
                  {...formState}
                  checked={value}
                  inputRef={ref}
                  label={moduleMsgs.moduleConfigurationPageSupportsEvaluation}
                  disabled={!formWatcher.supportsLearning}
                />
              );
            }}
          />{' '}
          <Controller
            name={'supportsPrediction'}
            control={control}
            render={({ field, fieldState, formState }) => {
              const { ref, value, ...rest } = field; // extract ref to pass as inputRef
              return (
                <IntlCheckbox
                  {...rest}
                  {...fieldState}
                  {...formState}
                  checked={value}
                  inputRef={ref}
                  label={moduleMsgs.moduleConfigurationPageSupportsPrediction}
                  disabled={!formWatcher.supportsLearning}
                />
              );
            }}
          />{' '}
          <Controller
            name={'supportsRealtimePrediction'}
            control={control}
            render={({ field, fieldState, formState }) => {
              const { ref, value, ...rest } = field; // extract ref to pass as inputRef
              return (
                <IntlCheckbox
                  {...rest}
                  {...fieldState}
                  {...formState}
                  checked={value}
                  inputRef={ref}
                  label={
                    moduleMsgs.moduleConfigurationPageSupportsRealtimePrediction
                  }
                  disabled={!formWatcher.supportsLearning}
                />
              );
            }}
          />
          <div className={styles.HeaderLeft}>
            <div className={styles.FullLine} />
            <span>Resources</span>
          </div>
          <Controller
            name={'resources'}
            control={control}
            rules={{
              validate: (value) => {
                const resourceValidationErrors = AUGUR_JOBS.map((job) =>
                  validateK8sResources(
                    value?.[job] || {},
                    undefined,
                    enableGpuFractions
                  )
                ).filter((entry) => !!entry);

                if (resourceValidationErrors.length > 0) {
                  return 'There was an error with the resource validation.';
                }
                return true;
              },
            }}
            render={({ field }) => {
              return (
                <ResourceInput
                  supportedJobs={formWatcher}
                  resources={field.value || {}}
                  onResourcesChange={field.onChange}
                />
              );
            }}
          />
          <div className={styles.ButtonsContainer}>
            <Button
              color={'primary'}
              disabled={Object.keys(dirtyFields).length === 0 || !isValid}
              label={commonMessages.submit}
              type={'submit'}
            />
            <Button
              color={'white'}
              label={commonMessages.cancel}
              onClick={() => reset(actualConfig.generalConfiguration)}
              disabled={!isDirty}
            />
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default GeneralConfigurationPage;
