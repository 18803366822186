import { EChartsOption } from 'echarts';
import React, { FC } from 'react';

import { useDimensions, useThemeColor } from '../../../../../../../utils';
import EChartWrapper, {
  ReactEChartsProps,
} from '../../../../../e-chart-wrapper/EChartWrapper';
import commonStyles from '../../../styles.module.scss';
import { ReportElementProps } from '../../../types/meta';
import { LineChartConfig, LineChartReportData } from '../type';

export type LineChartDevProps = {
  legend?: string;
};

export type Props = LineChartReportData & LineChartConfig & LineChartDevProps;

export const LineChart: FC<Props> = (props) => {
  const { data, xLabel, yLabel, legend, baseLine, helpLine, yAxisMin } = props;

  const primary = useThemeColor('primary');
  const shading = useThemeColor('primary', '-lighter90');

  const [ref, { width, height }] = useDimensions<HTMLDivElement>();

  const markLinesData = [];
  if (baseLine) {
    markLinesData.push({
      yAxis: baseLine,
    });
  }
  if (helpLine) {
    markLinesData.push([
      {
        coord: helpLine[0],
      },
      {
        coord: helpLine[1],
      },
    ]);
  }

  const formattedData: EChartsOption['series'] = {
    name: legend || '',
    type: 'line',
    data: data,
    color: primary,
    areaStyle: {
      opacity: 0.7,
      color: shading,
    },
    markLine: {
      // @ts-ignore
      data: markLinesData,
      symbol: 'none',
      label: {
        position: 'start',
        offset: [-3, 0],
        backgroundColor: 'white',
      },
    },
  };

  const option: ReactEChartsProps['option'] = {
    tooltip: {
      trigger: 'axis',
      confine: true,
      valueFormatter: (value) => {
        if (typeof value === 'number') {
          return String(value.toFixed(2));
        } else {
          return '';
        }
      },
    },
    grid: {
      containLabel: true,
      left: 18,
      top: 32,
      right: 12,
      bottom: 12,
    },
    xAxis: {
      name: xLabel,
      type: 'value',
      nameLocation: 'middle',
      nameGap: 22,
      min: 'dataMin',
      max: 'dataMax',
      axisLabel: {
        // 1.81192837132 => 1.81 but also 1 => 1. Number casting after parseFloat removes the trailing 0s
        formatter: (value) => String(Number(parseFloat(value.toFixed(2)))),
      },
    },
    yAxis: {
      name: yLabel,
      type: 'value',
      nameLocation: 'end',
      nameGap: 10,
      nameTextStyle: {
        // With the default (center?) the start of the label may get cut off
        align: 'left',
      },
      min: yAxisMin ?? 0,
      axisLabel: {
        // 1.81192837132 => 1.81 but also 1 => 1. Number casting after parseFloat removes the trailing 0s
        formatter: (value) => String(Number(parseFloat(value.toFixed(2)))),
      },
    },
    series: formattedData,
    animation: false,
  };

  return (
    <div ref={ref} className={commonStyles.chart}>
      <EChartWrapper option={option} style={{ width: width, height: height }} />
    </div>
  );
};

export type SingleProps = ReportElementProps<
  LineChartReportData,
  LineChartConfig
>;

export const LineChartSingle: FC<SingleProps> = ({ input, config }) => {
  return (
    <LineChart {...input.reportValue} {...config} legend={input.jobCode} />
  );
};
