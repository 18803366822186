import augurElementsMessages from 'common/dist/messages/augurs.elements';
import React, { FC } from 'react';
import { useForm, Controller } from 'react-hook-form';

import { ConfigTextInputLineConfig, TextInputLineConfig } from './type';
import { IntlCheckbox } from '../../../../../atoms/react-hook-form-input-elements/checkbox/Checkbox';
import { IntlTextInputLine } from '../../../../../atoms/react-hook-form-input-elements/text-input-line/TextInputLine';
import styles from '../../../../../organisms/element-configuration/styles.module.scss';
import { ConfigFormComponentProps } from '../../../common/utils';

const TextInputLineConfigFormComponent: FC<
  ConfigFormComponentProps<TextInputLineConfig>
> = ({ configValue, onConfigChange }) => {
  const { control, getValues } = useForm<ConfigTextInputLineConfig>({
    defaultValues: configValue,
  });

  const updateConfig = () => {
    const config = getValues();
    onConfigChange(config);
  };

  return (
    <div className={styles.verticalConfigLayout}>
      <Controller
        name='placeholder.value'
        control={control}
        render={({ field }) => (
          <IntlTextInputLine
            label={augurElementsMessages.placeholder}
            placeholder={augurElementsMessages.placeholder}
            {...field}
            onChange={(e) => {
              field.onChange(e);
              updateConfig();
            }}
          />
        )}
      />
      <Controller
        name='isOptional.value'
        control={control}
        render={({ field }) => (
          <IntlCheckbox
            label={augurElementsMessages.isOptional}
            {...field}
            checked={field.value}
            onChange={() => {
              field.onChange(!field.value);
              updateConfig();
            }}
          />
        )}
      />
    </div>
  );
};

export default TextInputLineConfigFormComponent;
