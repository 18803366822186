import { cleanErrorObject } from 'common/dist/validation/helpers';
import { DeepPartial } from 'react-hook-form';

import {
  S3FileSelectAugurSettings,
  S3FileSelectConfig,
  S3FileSelectValidationError,
} from './type';

export const validateS3FileSelect = (
  value: S3FileSelectAugurSettings,
  config: S3FileSelectConfig
): DeepPartial<S3FileSelectValidationError> | undefined => {
  const error: DeepPartial<S3FileSelectValidationError> = {};

  if (!value) {
    error.global = 'Missing value';
  }

  return cleanErrorObject(error);
};
