// npx ts-json-schema-generator --no-type-check --path 'src/js/components/molecules/augur-layout-elements/settings-elements/text-input-area/v1/type.ts' --type 'ConfigTextInputAreaConfig'
export const schemaConfigTextInputArea = {
  $ref: '#/definitions/ConfigTextInputAreaConfig',
  $schema: 'http://json-schema.org/draft-07/schema#',
  definitions: {
    'Config<TextInputAreaConfig>': {
      additionalProperties: false,
      description: 'Describes the non-resolved config of a layout element.',
      properties: {
        isOptional: {
          $ref: '#/definitions/ConfigEntry%3Calias-731470504-73452-73562-731470504-0-213718%3C(boolean%7Cundefined)%2Cundefined%3E%3E',
        },
        placeholder: {
          $ref: '#/definitions/ConfigEntry%3Calias-731470504-73452-73562-731470504-0-213718%3C(string%7Cundefined)%2Cundefined%3E%3E',
        },
      },
      type: 'object',
    },
    'ConfigEntry<alias-731470504-73452-73562-731470504-0-213718<(boolean|undefined),undefined>>':
      {
        anyOf: [
          {
            additionalProperties: false,
            properties: {
              source: {
                const: 'hard-coded',
                type: 'string',
              },
              value: {
                type: 'boolean',
              },
            },
            required: ['source', 'value'],
            type: 'object',
          },
          {
            additionalProperties: false,
            properties: {
              elementUuid: {
                type: 'string',
              },
              source: {
                const: 'input-element',
                type: 'string',
              },
            },
            required: ['source', 'elementUuid'],
            type: 'object',
          },
        ],
        description:
          'A single config entry. Is given a generic type T that corresponds to a field in a ConfigType of a layout element.',
      },
    'ConfigEntry<alias-731470504-73452-73562-731470504-0-213718<(string|undefined),undefined>>':
      {
        anyOf: [
          {
            additionalProperties: false,
            properties: {
              source: {
                const: 'hard-coded',
                type: 'string',
              },
              value: {
                type: 'string',
              },
            },
            required: ['source', 'value'],
            type: 'object',
          },
          {
            additionalProperties: false,
            properties: {
              elementUuid: {
                type: 'string',
              },
              source: {
                const: 'input-element',
                type: 'string',
              },
            },
            required: ['source', 'elementUuid'],
            type: 'object',
          },
        ],
        description:
          'A single config entry. Is given a generic type T that corresponds to a field in a ConfigType of a layout element.',
      },
    ConfigTextInputAreaConfig: {
      $ref: '#/definitions/Config%3CTextInputAreaConfig%3E',
    },
  },
};

// npx ts-json-schema-generator --no-type-check --path 'src/js/components/molecules/augur-layout-elements/settings-elements/text-input-area/v1/type.ts' --type 'PartialTextInputAreaAugurSettings'
export const schemaAugurSettingsDefaultTextInputArea = {
  $ref: '#/definitions/PartialTextInputAreaAugurSettings',
  $schema: 'http://json-schema.org/draft-07/schema#',
  definitions: {
    PartialTextInputAreaAugurSettings: {
      $ref: '#/definitions/TextInputAreaV1AugurSettings',
    },
    TextInputAreaV1AugurSettings: {
      description:
        'Name must match with the automatic conversion from type and version. Should only be imported directly by the element that produces it. This is only in common to make generating the jsonSchema and delivering it by the backend easier.',
      type: 'string',
    },
  },
};
