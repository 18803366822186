import PropTypes from 'prop-types';
import React, { Component } from 'react';

import Busy from '../../../atoms/busy/Busy';
import {
  FormattedDateTime,
  FormattedRelativeTimeConvenient,
} from '../../../atoms/formatted-date-time/FormattedDateTime';
import RunCodeCapsuleModal from '../../../runCodeCapsuleModal/RunCodeCapsuleModal.container';

export default class Versions extends Component {
  state = {
    runCodeCapsuleModalOpen: false,
    codeCapsuleModalProps: {},
  };

  constructor(props) {
    super(props);
    this.renderError = this.renderError.bind(this);
    this.renderLoaded = this.renderLoaded.bind(this);
    this.openRunCodeCapsuleModal = this.openRunCodeCapsuleModal.bind(this);
    this.closeRunCodeCapsuleModal = this.closeRunCodeCapsuleModal.bind(this);
  }

  openRunCodeCapsuleModal(codeCapsuleModalProps) {
    this.setState({
      runCodeCapsuleModalOpen: true,
      codeCapsuleModalProps,
    });
  }

  closeRunCodeCapsuleModal() {
    this.setState({
      runCodeCapsuleModalOpen: false,
      codeCapsuleModalProps: {},
    });
  }

  renderLoaded() {
    const { data, triggerRunCodeCapsuleJob, codeCapsuleCode } = this.props;

    const sortedVersions = data.versions;
    if (sortedVersions)
      sortedVersions.sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1));

    return (
      <div className={'code-capsule-container versions-container'}>
        <div className={'code-capsules custom-table'}>
          <div className={'ct-row ct-headline'}>
            <div className={'ct-col ct-col-280px'}>
              <span>Version Number</span>
            </div>
            <div className={'ct-col ct-col-200px'}>
              <span>Created At</span>
            </div>
          </div>

          {data &&
            data.versions &&
            data.versions.map((version, index) => (
              <div className={'ct-row'} key={index}>
                <div
                  className={'ct-col ct-col-280px'}
                  style={{
                    justifyContent: 'start',
                    display: 'flex',
                    alignItems: 'center',
                    height: '100%',
                    margin: 'auto',
                  }}
                >
                  <div className={'ct-value'}>
                    <span>{version.number}</span>
                  </div>
                </div>
                <div
                  className={'ct-col ct-col-200px'}
                  style={{
                    justifyContent: 'start',
                    display: 'flex',
                    alignItems: 'center',
                    height: '100%',
                    margin: 'auto',
                  }}
                >
                  <div
                    className={'ct-value'}
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <>
                      <strong
                        style={{
                          whiteSpace: 'nowrap',
                          textOverflow: 'ellipsis',
                          overflow: 'hidden',
                        }}
                      >
                        <FormattedRelativeTimeConvenient
                          date={new Date(version.createdAt)}
                          tooltip={false}
                        />
                      </strong>
                      <span
                        style={{
                          whiteSpace: 'nowrap',
                          textOverflow: 'ellipsis',
                          overflow: 'hidden',
                        }}
                      >
                        <FormattedDateTime date={new Date(version.createdAt)} />
                      </span>
                    </>
                  </div>
                </div>
                <div
                  className={'ct-col ct-col-flex-grow'}
                  style={{
                    justifyContent: 'end',
                    display: 'flex',
                    alignItems: 'center',
                    height: '100%',
                    margin: 'auto',
                  }}
                >
                  <ul className={'code-capsule-button-list'}>
                    <li className={'code-capsule-li'}>
                      <div
                        className={'code-capsule-run-now'}
                        onClick={() =>
                          this.openRunCodeCapsuleModal({
                            versions: [version],
                            name: data.name,
                            repoCode: data.repoCode,
                            codeCapsuleCode,
                            habitatCode: data.habitatCode,
                          })
                        }
                      >
                        <span className={'icon-play'} />
                        <span className={'button-text'}>
                          Run Code Capsule Now
                        </span>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            ))}
        </div>
        {this.state.runCodeCapsuleModalOpen && (
          <RunCodeCapsuleModal
            close={this.closeRunCodeCapsuleModal}
            onSubmit={triggerRunCodeCapsuleJob}
            {...this.state.codeCapsuleModalProps}
          />
        )}
      </div>
    );
  }

  renderLoading() {
    return (
      <div className={'code-capsule-container versions-container'}>
        <Busy isBusy />
      </div>
    );
  }

  renderError() {
    const { error } = this.props;
    return (
      <div className={'code-capsule-container versions-container'}>
        <span className={'error'}>{error}</span>
      </div>
    );
  }

  render() {
    const { loaded, loading, error } = this.props;
    if (loaded) return this.renderLoaded();
    else if (error) return this.renderError();
    else if (loading) return this.renderLoading();
    else return <div />;
  }
}
Versions.propTypes = {
  match: PropTypes.object.isRequired,
  codeCapsuleCode: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  loaded: PropTypes.bool,
  error: PropTypes.string,
  data: PropTypes.shape({
    code: PropTypes.string,
    name: PropTypes.string,
    repoCode: PropTypes.string,
    versions: PropTypes.arrayOf(
      PropTypes.shape({
        number: PropTypes.string.isRequired,
      })
    ),
    habitatName: PropTypes.string.isRequired,
    habitatCode: PropTypes.string.isRequired,
  }),
  triggerRunCodeCapsuleJob: PropTypes.func.isRequired,
};
Versions.defaultProps = {
  loading: false,
  loaded: false,
  data: {},
};
