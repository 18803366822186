import { TextInputLineV1AugurSettings as TextInputLineAugurSettings } from 'common/dist/types/augurSettings/textInputLineV1AugurSettings';
import { DeepPartial } from 'react-hook-form';

import { Config } from '../../../../../pages/augur/type';
import { AllowedInputs } from '../../types/util';
export type { TextInputLineAugurSettings };

export type TextInputLineConfig = {
  placeholder?: string;
  isOptional?: boolean;
};
export type ConfigTextInputLineConfig = Config<TextInputLineConfig>;

export type PartialTextInputLineAugurSettings = TextInputLineAugurSettings; // Partial<TextInputLineAugurSettings>

export type TextInputLineValidationError = DeepPartial<{ global: string }>;

export const configAllowedInputsTextInputLine: AllowedInputs<TextInputLineConfig> =
  {};

export const configTypeTextInputLine = `{ 
  placeholder: { source: 'hard-coded', value: string },
  isOptional: { source: 'hard-coded', value: boolean }
}`;

export const augurSettingsTypeTextInputLine = `string`;

export const defaultConfigTextInputLine: ConfigTextInputLineConfig = {
  placeholder: { source: 'hard-coded', value: 'placeholder' },
  isOptional: { source: 'hard-coded', value: false },
};

export const defaultAugurSettingsDefaultTextInputLine: PartialTextInputLineAugurSettings =
  '';

// JSONSchemaType<TextInputLineAugurSettings>
export const defaultValidationSchemaTextInputLine = {
  type: 'string',
};
