import classNames from 'classnames';
import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import { OrderByType, SortByType } from './sorting';
import styles from './styles.module.scss';
import AscDesc, {
  ASC_DESC_VALUE,
} from '../../../../../../atoms/ascDesc/AscDesc';

export type Props = {
  sort: (sortBy: SortByType, orderBy: OrderByType) => void;
  sortBy: SortByType;
  orderBy: OrderByType;
};

const Header: FC<Props> = (props: Props) => {
  const { sort, sortBy, orderBy } = props;

  return (
    <>
      <div className={classNames(styles.header, styles.importance)}>
        <AscDesc
          value={
            sortBy === 'importance'
              ? orderBy === 'ascending'
                ? ASC_DESC_VALUE.ASC
                : ASC_DESC_VALUE.DESC
              : undefined
          }
          onChange={(value) => {
            sort(
              'importance',
              value === ASC_DESC_VALUE.ASC ? 'ascending' : 'descending'
            );
          }}
        >
          <FormattedMessage
            id='details.rejectedFeatures.importance'
            defaultMessage='Importance'
          />
        </AscDesc>
      </div>

      <div className={classNames(styles.header, styles.type)}>
        <FormattedMessage
          id='details.rejectedFeatures.type'
          defaultMessage='Type'
        />
      </div>

      <div className={classNames(styles.header, styles.feature)}>
        <AscDesc
          value={
            sortBy === 'feature'
              ? orderBy === 'ascending'
                ? ASC_DESC_VALUE.ASC
                : ASC_DESC_VALUE.DESC
              : undefined
          }
          onChange={(value) => {
            sort(
              'feature',
              value === ASC_DESC_VALUE.ASC ? 'ascending' : 'descending'
            );
          }}
        >
          <FormattedMessage
            id='details.rejectedFeatures.feature'
            defaultMessage='Feature'
          />
        </AscDesc>
      </div>
    </>
  );
};

export default Header;
