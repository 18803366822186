import classNames from 'classnames';
import React, { FC } from 'react';

import styles from './styles.module.scss';
import {
  TextInputAreaAugurSettings,
  TextInputAreaConfig,
  TextInputAreaValidationError,
} from './type';
import InputError from '../../../../../atoms/input-error/InputError';
import { AugurSettingsProps } from '../../types/meta';

export interface TextInputAreaDevProps {
  amountRows?: number;
  initialCollapsed?: boolean;
  onToggle?: (isCollapsed: boolean) => void;
  title?: string;
  showInfo?: boolean;
}

export type Props = TextInputAreaDevProps &
  AugurSettingsProps<
    TextInputAreaAugurSettings,
    TextInputAreaConfig,
    TextInputAreaValidationError
  >;

const TextInputArea: FC<Props> = (props) => {
  const {
    config: { placeholder },
    amountRows = 5,
    title,
    disabled,
    onChange,
    onBlur,
    name,
    inputRef,
    error,
    isTouched,
    value,
  } = props;
  const fieldClassName = classNames(
    styles.TextInputAreaInput,
    isTouched && {
      [styles.error]: error,
    }
  );

  function renderDefaultElement() {
    return (
      <div className={styles.TextInputArea}>
        <textarea
          rows={amountRows}
          onChange={onChange}
          onBlur={onBlur}
          className={fieldClassName}
          placeholder={placeholder}
          disabled={disabled}
          style={{ resize: 'none' }}
          title={title}
          name={name}
          ref={inputRef}
          value={value ?? ''} // force controlled input
          aria-invalid={error ? 'true' : 'false'}
        />
      </div>
    );
  }

  return renderDefaultElement();
};

export default TextInputArea;
