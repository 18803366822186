import { cleanErrorObject } from 'common/dist/validation/helpers';
import { DeepPartial } from 'react-hook-form';

import {
  DropdownSelectCFEAugurSettings,
  DropdownSelectCFEConfig,
  DropdownSelectCFEValidationError,
} from './type';

export const validateDropdownSelectCFE = (
  value: DropdownSelectCFEAugurSettings,
  config: DropdownSelectCFEConfig
): DeepPartial<DropdownSelectCFEValidationError> | undefined => {
  const error: DeepPartial<DropdownSelectCFEValidationError> = {};

  if (!value?.length) {
    error.global = config.isMulti
      ? 'Please select at least one option'
      : 'Please select an option';
  }
  return cleanErrorObject(error);
};
