import { PipelineTuningSchemaType } from 'common/src/types/pipeline';

export const exampleSchema1: PipelineTuningSchemaType = {
  id: 'standardMethods',
  displayName: 'Standard Methods',
  optimizationMethod: {
    id: 'grid_search_cross_validation',
    nSplits: 5,
  },
  nodes: [
    {
      type: 'node',
      nodeType: 'transformer',
      id: 'column_rejector',
      isOptional: false,
      isEditable: false,
      staticParameters: [],
      tuningParameters: [],
      isTuneable: false,
      displayName: 'Rejected Features Filter',
      description:
        'Drops the Rejected Feature columns from the data. The Rejected Features can be defined in the Influencers tab of the Augur Details.',
    },
    {
      type: 'node',
      nodeType: 'transformer',
      id: 'extra_column_rejector',
      isOptional: false,
      staticParameters: [],
      tuningParameters: [],
      isEditable: false,
      isTuneable: false,
      displayName: 'Unknown Column Rejector',
      description:
        'Drops columns from the Data that were not present during training. This node is only relevant for evaluation and prediction jobs and will be skipped in learning jobs.',
    },
    {
      type: 'node',
      nodeType: 'transformer',
      id: 'type_discarder',
      isOptional: false,
      isEditable: false,
      staticParameters: [],
      tuningParameters: [],
      isTuneable: false,
      displayName: 'Unsupported Column Type Discarder',
      description:
        'Drops columns containing unsupported data types (timestamps and dates) from the data',
    },
    {
      type: 'node',
      nodeType: 'transformer',
      id: 'too_many_values',
      isOptional: true,
      isEditable: true,
      tuningParameters: [],
      isTuneable: false,
      displayName: 'Too Many Values Discarder',
      description:
        'Drops categorical columns with more than Distinct Values Threshold many values from the data',

      staticParameters: [
        {
          id: 'values_threshold',
          displayName: 'Distinct Values Threshold',
          description:
            'Absolute amount of distinct values above which the column will be discarded.',
          name: 'Distinct Values Threshold',
          validValues: [
            {
              type: 'int',
              minValue: 2,
              maxValue: 1000,
            },
          ],
        },
      ],
    },

    {
      type: 'group',
      id: 'imputer_group',
      displayName: 'Missing Value Imputation',
      nodes: [
        {
          type: 'node',
          nodeType: 'transformer',
          id: 'mean_inputer',
          isOptional: true,
          isEditable: true,
          tuningParameters: [],
          isTuneable: false,
          displayName: 'Mean Imputer',
          description:
            'Fills missing values in numeric columns with their mean',

          staticParameters: [
            {
              id: 'na_threshold',
              displayName: 'NA Threshold',
              description:
                'Threshold (as percentage) of missing values in column above which the column is discarded.',
              name: 'NA Theshold',
              validValues: [
                {
                  type: 'double',
                  minValue: 0,
                  maxValue: 1,
                },
              ],
            },
          ],
        },
        {
          type: 'node',
          nodeType: 'transformer',
          id: 'median_imputer',
          isOptional: true,
          isEditable: true,
          isTuneable: false,
          displayName: 'Median Imputer',
          description:
            'Fills missing values in numeric columns with their median',
          tuningParameters: [],
          staticParameters: [
            {
              id: 'na_threshold',
              displayName: 'NA Threshold',
              description:
                'Threshold (as percentage) of missing values in column above which the column is discarded.',
              name: 'NA Threshold',
              validValues: [
                {
                  type: 'double',
                  minValue: 0,
                  maxValue: 1,
                },
              ],
            },
          ],
        },
        {
          type: 'node',
          nodeType: 'transformer',
          id: 'constant_value_imputer',
          isOptional: true,
          isEditable: true,
          isTuneable: false,
          displayName: 'Constant Value Imputer',
          description:
            'Fills missing values in numeric columns with a constant value',
          tuningParameters: [],
          staticParameters: [
            {
              id: 'na_threshold',
              displayName: 'NA Threshold',
              description:
                'Threshold (as percentage) of missing values in column above which the column is discarded.',
              name: 'NA Threshold',
              validValues: [
                {
                  type: 'double',
                  minValue: 0,
                  maxValue: 1,
                },
              ],
            },
          ],
        },
      ],
    },
    {
      type: 'node',
      nodeType: 'transformer',
      id: 'low_information_discarder',
      isOptional: true,
      isEditable: true,
      tuningParameters: [],
      isTuneable: false,
      displayName: 'Low Information Discarder',
      description:
        'Only keeps the top k many columns with the highest information value. The information value is determined by univariate statistical analysis.',

      staticParameters: [
        {
          id: 'k_best',
          displayName: 'K Best',
          description:
            'Amount of parameters to trim down the training set to. Keeps the k columns with the highest information.',
          name: 'K Best',
          validValues: [
            {
              type: 'int',
              minValue: 1,
              maxValue: 1000,
            },
          ],
        },
      ],
    },
    {
      type: 'node',
      nodeType: 'transformer',
      id: 'one_hot_encoder',
      staticParameters: [],
      tuningParameters: [],
      isOptional: false,
      isEditable: false,
      isTuneable: false,
      displayName: 'One Hot Encoder',
      description: 'Replaces categorical columns with a one hot encoding.',
    },
    {
      type: 'group',
      id: 'classifier_group_tb',
      displayName: 'Tree-Based',
      nodes: [
        {
          type: 'node',
          nodeType: 'classifier',
          id: 'decision_tree_classifier',
          isOptional: true,
          staticParameters: [],
          isEditable: false,
          isTuneable: true,
          displayName: 'Decision Tree Classifier',
          description: 'Decision Tree Classifier',

          tuningParameters: [
            {
              id: 'max_depth',
              name: 'max_depth',
              displayName: 'Max Depth',
              description: 'The maximum depth of the tree.',

              validValues: [
                {
                  type: 'int',
                  minValue: 1,
                  maxValue: 1000,
                },
              ],
            },
            {
              id: 'min_samples_split',
              name: 'min_samples_split',
              displayName: 'Min Samples Split',
              description:
                'The minimum number of samples required to split an internal node.',

              validValues: [
                {
                  type: 'int',
                  minValue: 1,
                  maxValue: 1000,
                },
                {
                  type: 'double',
                  minValue: 0.01,
                  maxValue: 1.0,
                },
              ],
            },
            {
              id: 'min_samples_leaf',
              name: 'min_samples_leaf',
              displayName: 'Min Samples Leaf',
              description:
                'The minimum number of samples required to be at a leaf node.',

              validValues: [
                {
                  type: 'int',
                  minValue: 1,
                  maxValue: 1000,
                },
                {
                  type: 'double',
                  minValue: 0.01,
                  maxValue: 1.0,
                },
              ],
            },
          ],
        },
        {
          type: 'node',
          nodeType: 'classifier',
          id: 'random_forest_classifier',
          isOptional: true,
          isEditable: false,
          isTuneable: true,
          displayName: 'Random Forest Classifier',
          description: 'Random Forest Classifier',
          staticParameters: [],
          tuningParameters: [
            {
              id: 'n_estimators',
              name: 'n_estimators',
              displayName: 'Amount of Trees',
              description: 'Number of trees in the ensemble.',

              validValues: [
                {
                  type: 'int',
                  minValue: 1,
                  maxValue: 1000,
                },
              ],
            },
            {
              id: 'max_depth',
              name: 'max_depth',
              displayName: 'Max Depth',
              description: 'The maximum depth of the trees.',

              validValues: [
                {
                  type: 'int',
                  minValue: 1,
                  maxValue: 100,
                },
              ],
            },
            {
              id: 'min_samples_split',
              name: 'min_samples_split',
              displayName: 'Min Samples Split',
              description:
                'The minimum number of samples required to split an internal node.',

              validValues: [
                {
                  type: 'int',
                  minValue: 1,
                  maxValue: 100,
                },
                {
                  type: 'double',
                  minValue: 0.01,
                  maxValue: 1.0,
                },
              ],
            },
            {
              id: 'min_samples_leaf',
              name: 'min_samples_leaf',
              displayName: 'Min Samples Leaf',
              description:
                'he minimum number of samples required to be at a leaf node.',

              validValues: [
                {
                  type: 'int',
                  minValue: 1,
                  maxValue: 100,
                },
                {
                  type: 'double',
                  minValue: 0.01,
                  maxValue: 1.0,
                },
              ],
            },
            {
              id: 'max_features',
              name: 'max_features',
              displayName: 'Max Features',
              description:
                'The number of features to consider when looking for the best split',

              validValues: [
                {
                  type: 'double',
                  minValue: 0.01,
                  maxValue: 100,
                },
                {
                  type: 'int',
                  minValue: 1,
                  maxValue: 1000,
                },
                {
                  type: 'string',
                  values: ['auto', 'sqrt', 'log2'],
                },
              ],
            },
          ],
        },
      ],
    },
    {
      type: 'group',
      id: 'classifier_group_gb1',
      displayName: 'Gradient-Boosting',
      nodes: [
        {
          type: 'node',
          nodeType: 'classifier',
          id: 'gradient_boosting_classifier',
          isOptional: true,
          isEditable: false,
          staticParameters: [],
          isTuneable: true,
          displayName: 'Gradient Boosting Classifier',
          description: 'Gradient Boosting Classifier',

          tuningParameters: [
            {
              id: 'n_estimators',
              name: 'n_estimators',
              displayName: 'Amount of Boosting Stages',
              description: 'Number of boosting stages to perform',

              validValues: [
                {
                  type: 'int',
                  minValue: 1,
                  maxValue: 1000,
                },
              ],
            },
            {
              id: 'learning_rate',
              name: 'learning_rate',
              displayName: 'Learning rate',
              description: 'Learning rate controls contribution of each tree',

              validValues: [
                {
                  type: 'double',
                  minValue: 0,
                  maxValue: 1,
                },
              ],
            },
            {
              id: 'max_depth',
              name: 'max_depth',
              displayName: 'Max Depth',
              description: 'Max Depth of individual regression estimators',

              validValues: [
                {
                  type: 'int',
                  minValue: 1,
                  maxValue: 100,
                },
              ],
            },
          ],
        },
        {
          type: 'node',
          nodeType: 'classifier',
          id: 'xgboost_classifier',
          isOptional: true,
          isEditable: false,
          staticParameters: [],
          isTuneable: true,
          displayName: 'XGBoost Classifier',
          description: 'XGBoost Classifier',

          tuningParameters: [
            {
              id: 'max_depth',
              name: 'max_depth',
              displayName: 'Max Depth',
              description: 'Max Depth of individual estimators',

              validValues: [
                {
                  type: 'int',
                  minValue: 1,
                  maxValue: 100,
                },
              ],
            },
            {
              id: 'learning_rate',
              name: 'learning_rate',
              displayName: 'Learning Rate',
              description: 'Learning rate controls contribution of each tree',

              validValues: [
                {
                  type: 'double',
                  minValue: 0,
                  maxValue: 1,
                },
              ],
            },
          ],
        },
      ],
    },
    {
      type: 'group',
      id: 'classifier_group_gb2',
      displayName: 'Gradient-Boosting',
      nodes: [
        {
          type: 'node',
          nodeType: 'classifier',
          id: 'catboost_classifier',
          isOptional: true,
          isEditable: false,
          staticParameters: [],
          isTuneable: true,
          displayName: 'Catboost Classifier',
          description: 'Catboost Classifier',

          tuningParameters: [
            {
              id: 'depth',
              name: 'depth',
              displayName: 'Depth',
              description: 'Depth of the tree',

              validValues: [
                {
                  type: 'int',
                  minValue: 1,
                  maxValue: 16,
                },
              ],
            },
            {
              id: 'n_estimators',
              name: 'n_estimators',
              displayName: 'Amount of Boosting Stages',
              description: 'Number of boosting stages to perform',

              validValues: [
                {
                  type: 'int',
                  minValue: 1,
                  maxValue: 1000,
                },
              ],
            },
          ],
        },
      ],
    },
  ],
  edges: [
    {
      sourceID: 'column_rejector',
      targetID: 'extra_column_rejector',
    },
    {
      sourceID: 'extra_column_rejector',
      targetID: 'type_discarder',
    },
    {
      sourceID: 'type_discarder',
      targetID: 'too_many_values',
    },
    {
      sourceID: 'too_many_values',
      targetID: 'imputer_group',
    },
    {
      sourceID: 'imputer_group',
      targetID: 'low_information_discarder',
    },
    {
      sourceID: 'low_information_discarder',
      targetID: 'one_hot_encoder',
    },
    {
      sourceID: 'one_hot_encoder',
      targetID: 'classifier_group_tb',
    },
    {
      sourceID: 'one_hot_encoder',
      targetID: 'classifier_group_gb1',
    },
    {
      sourceID: 'low_information_discarder',
      targetID: 'classifier_group_gb2',
    },
  ],
};
