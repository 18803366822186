import { K8sResources } from 'common/dist/types/job';
import {
  getCpuValueInMilliunits,
  getQuantityInBytes,
  humanFileSize,
  isValidK8sCpuSpec,
  isValidK8sMemorySpec,
} from 'common/dist/utils/kubernetes';

import { ErrorType } from './K8sResourcesSelect';
import {
  WB_MAX_CPU,
  WB_MAX_GPU,
  WB_MAX_MEM,
} from '../../admin/users/user-details/Attributes.form';

export const validateK8sResources = (
  value: K8sResources,
  attributes?: Record<string, string[]>,
  enableGpuFractions?: boolean
): ErrorType => {
  const error: ErrorType = {};
  const { cpuRequest, cpuLimit, memoryRequest, memoryLimit, gpuLimit, useGpu } =
    value;

  // Cpu Request
  let cpuRequestValid = true;
  if (cpuRequest) {
    cpuRequestValid = isValidK8sCpuSpec(cpuRequest);
    if (!cpuRequestValid) {
      error['cpuRequest'] = 'Please enter valid CPU request.';
    }
    if (
      attributes &&
      attributes[WB_MAX_CPU] &&
      attributes[WB_MAX_CPU].length > 0 &&
      isValidK8sCpuSpec(attributes[WB_MAX_CPU][0]) &&
      getCpuValueInMilliunits(attributes[WB_MAX_CPU][0]) <
        getCpuValueInMilliunits(cpuRequest)
    ) {
      error['cpuRequest'] = `You are not allowed to use more than ${
        getCpuValueInMilliunits(attributes[WB_MAX_CPU][0]) / 1000
      } CPU.`;
    }
  }

  // Cpu Limit
  let cpuLimitValid = true;
  if (cpuLimit) {
    cpuLimitValid = isValidK8sCpuSpec(cpuLimit);
    if (!cpuLimitValid) {
      error['cpuLimit'] = 'Please enter valid CPU limit.';
    }
    if (
      attributes &&
      attributes[WB_MAX_CPU] &&
      attributes[WB_MAX_CPU].length > 0 &&
      isValidK8sCpuSpec(attributes[WB_MAX_CPU][0]) &&
      getCpuValueInMilliunits(attributes[WB_MAX_CPU][0]) <
        getCpuValueInMilliunits(cpuLimit)
    ) {
      error['cpuLimit'] = `You are not allowed to use more than ${
        getCpuValueInMilliunits(attributes[WB_MAX_CPU][0]) / 1000
      } CPU.`;
    }
  }

  // Memory Request
  let memoryRequestValid = true;
  if (memoryRequest) {
    memoryRequestValid = isValidK8sMemorySpec(memoryRequest, true);
    if (!memoryRequestValid) {
      error['memoryRequest'] = 'Please enter valid memory request.';
    }
    if (
      attributes &&
      attributes[WB_MAX_MEM] &&
      attributes[WB_MAX_MEM].length > 0 &&
      isValidK8sMemorySpec(attributes[WB_MAX_MEM][0], true) &&
      getQuantityInBytes(attributes[WB_MAX_MEM][0]) <
        getQuantityInBytes(memoryRequest)
    ) {
      error[
        'memoryRequest'
      ] = `You are not allowed to use more than ${humanFileSize(
        getQuantityInBytes(attributes[WB_MAX_MEM][0])
      )} of memory.`;
    }
  }

  // Memory Limit
  let memoryLimitValid = true;
  if (memoryLimit) {
    memoryLimitValid = isValidK8sMemorySpec(memoryLimit, true);
    if (!memoryLimitValid) {
      error['memoryLimit'] = 'Please enter valid memory limit.';
    }
    if (
      attributes &&
      attributes[WB_MAX_MEM] &&
      attributes[WB_MAX_MEM].length > 0 &&
      isValidK8sMemorySpec(attributes[WB_MAX_MEM][0], true) &&
      getQuantityInBytes(attributes[WB_MAX_MEM][0]) <
        getQuantityInBytes(memoryLimit)
    ) {
      error[
        'memoryLimit'
      ] = `You are not allowed to use more than ${humanFileSize(
        getQuantityInBytes(attributes[WB_MAX_MEM][0])
      )} of memory.`;
    }
  }

  // CPU Request < CPU Limit
  if (cpuRequest && cpuLimit && cpuRequestValid && cpuLimitValid) {
    const cpuRequestMilliunit = getCpuValueInMilliunits(cpuRequest);
    const cpuLimitMilliunit = getCpuValueInMilliunits(cpuLimit);
    if (cpuRequestMilliunit >= cpuLimitMilliunit) {
      error['cpuLimit'] = 'CPU limit must be larger than the CPU request.';
    }
  }

  // Memory Request < Memory Limit
  if (memoryRequest && memoryLimit && memoryRequestValid && memoryLimitValid) {
    const requestBytes = getQuantityInBytes(memoryRequest);
    const limitBytes = getQuantityInBytes(memoryLimit);
    if (requestBytes >= limitBytes) {
      error['memoryLimit'] =
        'Memory limit must be larger than the memory request.';
    }
  }
  // GPU Limit
  let gpuLimitValid = true;
  if (gpuLimit) {
    gpuLimitValid = isValidK8sCpuSpec(
      gpuLimit,
      !enableGpuFractions,
      enableGpuFractions
    );
    if (!gpuLimitValid) {
      error['gpuLimit'] = 'Please enter a valid GPU limit.';
    }
    if (
      attributes &&
      attributes[WB_MAX_GPU] &&
      attributes[WB_MAX_GPU].length > 0 &&
      (isValidK8sCpuSpec(
        attributes[WB_MAX_GPU][0],
        !enableGpuFractions,
        enableGpuFractions
      ) ||
        attributes[WB_MAX_GPU][0] === '0') &&
      getCpuValueInMilliunits(attributes[WB_MAX_GPU][0]) <
        getCpuValueInMilliunits(gpuLimit)
    ) {
      error['gpuLimit'] = `You are not allowed to use more than ${
        getCpuValueInMilliunits(attributes[WB_MAX_GPU][0]) / 1000
      } GPU.`;
    }
    if (useGpu && getCpuValueInMilliunits(gpuLimit) <= 0)
      error['gpuLimit'] = 'Cannot use GPU with limit 0.';
  }
  if (useGpu) {
    if (
      attributes &&
      attributes[WB_MAX_GPU] &&
      attributes[WB_MAX_GPU].length > 0 &&
      (isValidK8sCpuSpec(
        attributes[WB_MAX_GPU][0],
        !enableGpuFractions,
        enableGpuFractions
      ) ||
        attributes[WB_MAX_GPU][0] === '0') &&
      getCpuValueInMilliunits(attributes[WB_MAX_GPU][0]) <= 0
    ) {
      error['useGpu'] = `You are not allowed to use GPU.`;
    }
  }
  if (Object.keys(error).length > 0) return error;
  else return undefined;
};
