import React, { FC } from 'react';

import styles from './styles.module.scss';
import { DeIntl } from '../intl-wrapper/type';

export type Props = {
  title: string;
  description: string;
};

const ErrorTile: FC<DeIntl<Props>> = ({ title, description }) => {
  return (
    <div className={styles.container}>
      <span className={styles.title}>{title}</span>
      <span className={styles.description}>{description}</span>
    </div>
  );
};

export default ErrorTile;
