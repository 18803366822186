import { defineMessages } from 'react-intl';

export default defineMessages({
  headline: {
    id: 'error.headline',
    defaultMessage: 'An unexpected error occurred.',
  },
  description: {
    id: 'error.description',
    defaultMessage: 'Try reloading the page.',
  },
  s3Buckets: {
    id: 'error.s3.buckets',
    defaultMessage: `An error occurred while fetching the buckets for dataSource { dataSourceCode }.`,
  },
  s3Bucket: {
    id: 'error.s3.bucket',
    defaultMessage: `An error occurred while fetching the bucket { bucketName }.`,
  },
  s3FilePreview: {
    id: 'error.s3.file_preview',
    defaultMessage: `An error occurred while fetching preview for file { fileName }.`,
  },
  cassandraKeyspaces: {
    id: 'error.cassandra.keyspaces',
    defaultMessage: `An error occurred while fetching the keyspaces for dataSource { dataSourceCode }.`,
  },
  cassandraKeyspace: {
    id: 'error.cassandra.keyspace',
    defaultMessage: `An error occurred while fetching the keyspace { keyspaceName }.`,
  },
  cassandraTablePreview: {
    id: 'error.cassandra.table_preview',
    defaultMessage: `An error occurred while fetching preview for table { tableName }.`,
  },
});
